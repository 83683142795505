// import { createApp } from 'vue'
import {createStore} from 'vuex'

export default createStore({
  state: {
    menus:'',
    clickMenus:'',
    ConsultationHotline:'',
    baseURL:process.env.VUE_APP_STORE ? process.env.VUE_APP_STORE : process.env.VUE_APP_BASEURL
  },
  getters:{
    menus:(state)=>{
      return state.menus
    },
    clickMenus:(state)=>{
      return state.clickMenus
    },
    baseURL:(state) => {
      return state.baseURL
    },
    ConsultationHotline:(state) => {
      return state.ConsultationHotline
    }
  },
  mutations: {
    setMenus(state,v){
        state.menus = v
    },
    setClickMenus(state,v){
        state.clickMenus = v
    },
    setBaseURL(state,v){
      state.baseURL = v
    },
    setConsultationHotline(state,v){
      state.ConsultationHotline = v
    }
  }
})