// import Vue from 'vue'
import { createApp } from 'vue'
import {createRouter,createWebHashHistory} from 'vue-router'
// import index from '@/pages/index'

const  routes = [{
    path: '/',
    name: 'index',
    component: () => import ('@/pages/index')
}]

// createApp(App).use(Router).mount('#app')

export const router = createRouter({
    history:createWebHashHistory(),
    routes:routes
})

export default router