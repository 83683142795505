import { createApp } from 'vue'
import App from './App.vue'
import ElementUI from 'element-plus';
import router from './router'
import Vuex from 'vuex'
import store from './store/index'
import { Button, Carousel } from 'ant-design-vue';
import VueAMap, {initAMapApiLoader} from '@vuemap/vue-amap';
import 'lib-flexible/flexible'
import 'element-plus/dist/index.css';
import '@vuemap/vue-amap/dist/style.css'
import './assets/less/index.css'
initAMapApiLoader({
    key: 'd86d7bfb98045604af39549da91f648d',
    plugin: ['AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PlaceSearch', 'AMap.Geolocation'],
    uiVersion: '1.0.11' // 版本号
})
if (
    process.env.VUE_APP_MOBILE&&
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
  ) {
    location.href = process.env.VUE_APP_MOBILE;
    // return false;
  }else{
    createApp(App).use(ElementUI).use(router).use(Vuex).use(store).use(VueAMap).use(Button).use(Carousel).mount('#app')
  }

