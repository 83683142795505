<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "App",
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
html,
body,
#app {
  width: 100%;
  height: 100%;
  font-family: SourceHanSansCN-Regular;
}
.action {
  transition: all 1s;
}
.scroll-hidden {
  opacity: 0 !important;
  transform: translateY(50px);
}
.scroll-visible {
  opacity: 1;
  transform: translateY(0);
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  --webkit-box-shadow: inset 0 0 6px rgba(206, 106, 106, 0.3);

  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;

  background: rgb(215, 216, 215);

  --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(189, 119, 119, 0.4);
}
</style>
